(function ($) {
    // handle default text on file inputs
    $('input[type="file"]').on("change", function () {
        if ($(this).val() != "") {
            $(this).css("color", "#15817d");
        } else {
            $(this).css("color", "transparent");
        }
    });

    // handle news story images
    $newsStoryImages = $(".single-news-story .post-content img");

    function isEven(value) {
        if (value % 2 == 0) return true;
        else return false;
    }

    $newsStoryImages.each(function (index) {
        if (isEven(index)) {
            $(this).addClass("pull-left");
        } else {
            $(this).addClass("pull-right");
        }
    });

    // add an active class to Bootstrap accordion card
    $(".collapse").on("show.bs.collapse", function () {
        $(this).parent().addClass("active");
    });

    $(".collapse").on("hide.bs.collapse", function () {
        $(this).parent().removeClass("active");
    });


    // play video
    var videoButtons = $(".play-video")
    videoButtons.each(function () {
        var videoButton = this;
        var thisVideo = $(this).parent().find(".video iframe");

        videoButton.addEventListener("click", function () {
            $(this).hide();
            thisVideo[0].src += "&autoplay=1";
        });
    });

    // show/hide contact flag on scroll
    var contactFlag = $(".contact-flag");
    var appScroll = appScrollForward;
    var appScrollPosition = 0;
    var scheduledAnimationFrame = false;

    function appScrollReverse() {
        scheduledAnimationFrame = false;
        if ( appScrollPosition > 250 )
            return;
        contactFlag.removeClass( "reveal" );
        appScroll = appScrollForward;
    }

    function appScrollForward() {
        scheduledAnimationFrame = false;
        if ( appScrollPosition < 250 )
            return;
        contactFlag.addClass( "reveal" );
        appScroll = appScrollReverse;
    }

    function appScrollHandler() {
        appScrollPosition = window.pageYOffset;
        if ( scheduledAnimationFrame )
            return;
        scheduledAnimationFrame = true;
        requestAnimationFrame( appScroll );
    }

    $(window).on("scroll", appScrollHandler);

})(jQuery);
